/**
 * Staff - all
 */

main.staff {

}

.staff-left, .staff-right { width: 100%; }

// Left content
.staff-left {
	margin: 20px 0 0;
}

section.aisatu {
	color: #333;
	font-size: 13px;
	line-height: 1.8;
	> p {
		margin: 15px 0 0;
	}
}

section.staffs {
	margin: 20px 0 0;
	> h2 {
		color: #3b653b;
		font-weight: bold;
		margin: 20px 0 0;
	}
	> h3 {
		font-size: 14px;
		font-weight: bold;
		margin: 10px 0 0;
		> span { color: #00984f; }
	}
	> p {
		font-size: 12px;
		margin: 5px 0 0;
	}
	> figure { margin: 20px 0 0; }
}

.staff-table-wrap {
	margin: 10px 0 0;
	overflow-x: auto;
}
table.staff-schedule {
	// width: 450px;
	border: 1px solid #666;
	border-collapse: collapse;
	font-size: 12px;
	th, td {
		border: 1px solid #333;
		padding: 2px 0;
		text-align: center;
		vertical-align: middle;
		width: 56px;
		&:first-child { width: 66px; }
	}
	th { font-weight: bold; }
	thead {
		th {
			background: #dbffdb;
		}
	}
	tbody {
		td {
			> span { display: block; }
		}
	}
}

// Right content
.staff-right {
	margin: 30px 0 0;
}

.incho-main {
	> .text {
		width: 100%;
		margin: 10px 0 0;
		> h2 {
			font-size: 14px;
			> small {
				font-size: 12px;
			}
		}
		> h3, > h4 { font-size: 11px; font-weight: bold; margin: 5px 0;}
		> p, ul { font-size: 10px; }
	}
}

.incho-sub {
	> h4, ul {
		font-size: 10px;
		padding: 0 0 0 1em;
	}
	> h3 {
		font-size: 11px;
		margin: 15px 0 0;
	}
	
	> h4 {
		margin: 10px 0 0;
		text-indent: -1em;
		&:before { content: "・"; }
	}
}