/**
 * CSS for top page
 */

section.top-left,
section.top-right { width: 100; }

// Left content
section.top-left {
	margin: 20px 0 0;
	> p {
		font-size: 14px;
		line-height: 1.5;
		margin: 10px 0 0;
	}
}

picture.gaikan, {
	> img { width: 100%; }
}

div.taisho {
	align-items: flex-end;
	margin: 10px 0 0;
	> figure {
		width: 100%;
		text-align: center;
	}
	> .text {
		width: 100%;
		margin: 10px 0 0;
		background: #049239;
		color: #fff;
		padding: 15px;
		text-align: center;
		> h2 {
			font-size: 14px;
		}
		> p {
			font-size: 12px;
			line-height: 1.8;
			margin: 5px 0 0;
		}
	}
}

figure.taisho {
	> img { width: 100%; }
}

// Right content
section.top-right {
	margin: 20px 0 0;
}

section.service-hour {
	border-width: 0 5px 4px 5px;
	border-style: solid;
	border-color: #583c32;
	font-size: 12px;
	font-weight: normal;
	line-height: 1.5;
	padding: 0 0 3px;
	text-align: center;
	> h2 {
		background: #583c32;
		color: #fff;
		font-size: 16px;
		letter-spacing: 2px;
		padding: 3px 0;
	}
	> p {
		font-size: 14px;
		margin: 4px 0 0;
	}
}

section.top-topic {
	margin: 10px 0 0;
	> h2 {
		position: relative;
		width: 240px;
		> b {
			position: absolute;
			left: 104px;
			top: 7px;
			color: #fbf519;
			display: inline-block;
			font-size: 12px;
			line-height: 1;
			text-shadow: 0 0 1px rgba(0,0,0,.2);
		}
	}
}

dl.top-topic {
	border: 1px solid #999;
	border-top: 1px solid #f5ddc2;
	overflow: scroll;
	padding: 10px;
	> dt {
		color: #583c32;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 1px;
		margin: 10px 0 0;
		padding: 2px 8px 0;
		text-shadow: 0 1px 1px rgba(0,0,0,.1);
		text-transform: uppercase;
		&:first-child { margin: 0; }
		&.red {
			border-color: #ffb87e;
			color: #ed0016;
		}
	}
	> dd {
		font-size: 12px;
		line-height: 1.5;
		margin: 2px 0 0;
		padding: 0 10px;
		> a {
			> strong { color: #3c7ec2; }
			&:hover { text-decoration: none; }
		}
	}
}

section.night-icu {
	margin: 10px 0 0;
	> .text {
		background: #00984f;
		color: #fff;
		padding: 10px;
		> p { line-height: 1.5; }
		> p:first-child { font-size: 13px; letter-spacing: 1px; }
		> p:last-child { font-size: 12px; margin:5px 0 0; text-align: center; }
	}
	> picture {
		margin: 10px 0 0;
		> img { width:100%; }
	}
	> p:last-child {
		font-size: 12px;
		line-height: 1.5;
		margin: 5px 0 0;
		text-align: center;
	}
}