/**
 * About
 */

.about h2 {
	@extend .ms-gothic;
	font-size: 12pt;
	font-weight: bold;
	color: #006400;
	line-height: 100%;
	margin: 1.33em 0 5px; 
}

// Left content
.about-left {
	margin: 20px 0 0;
}

section.hours {
	border: 2px solid #523F36;
	color: #523f36;
	font-size: 16px;
	line-height: 1.4;
	margin: 15px 0 0;
	padding: 10px;
	text-align: center;
	> h2 { color: #523f36; margin: 0 0 5px; }
	> p:last-child { font-size: 12px; margin: 5px 0 0; }
}

section.emergency {
	p {
		font-size: 12px;
	}
}

section.payment {
	border: 2px solid #00984f;
	margin: 15px 0 0;
	padding: 10px;
	> h2 {
		color: #523f36;
		font-size: 15px;
		margin: 0 0 10px;
		text-align: center;
		text-decoration: underline;
	}
	> p {
		font-size: 11px;
		margin: 5px 0 0;
	}
	> figure {
		margin: 10px 0 0;
		text-align: center;
	}
}

// Right content
.about-table {
	font-size: 14px;
	th, td {
		display: block;
		text-align: left;
	}
	th { font-weight: bold; padding: 5px 14px 2px 0; }
}

section.map {
	> figure { margin: 10px 0 0; }
}

// Licence Table
.about-licence-tbl {
	width: 100%;
	font-size: 13px;
	line-height: 18px;
	margin: 20px 0 0;
	caption {
		background: #5b3b2e;
		color: #fff;
		font-size: 14px;
		padding: 4px 8px;
	}
	td {
		width: 50%;
		border: 1px solid #5b3b2e;
		padding: 4px 8px;
		&:first-child { background: #fef6f3; }
	}
}
