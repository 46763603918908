/**
 * Treatment - all
 */

main.treatment {
	background: url() no-repeat center/cover;
}

.treatment-wrap,
.treatment-wrap-2 {
	padding: 40px 0 20px;
	h1 {
		text-shadow: 0 0 2px rgba(59, 101, 63,.1)
	}
	h2 {
		@extend .ms-gothic;
		font-size: 12pt;
		font-weight: bold;
		color: #006400;
		line-height: 100%;
		margin: 1.33em 0 5px;
	}
	p {
		text-shadow: 0 0 2px rgba(0,0,0,.1)
	}
	p:nth-child(2) {
		line-height: 30px;
		margin: 20px 0 0;
	}
	p:last-child {
		font-size: 13px;
		margin: 10px 0 0 12pt;
	}
}

.treatment-wrap-2 {
	display: flex;
	flex-wrap: wrap;
}

.ct-pics {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 5px;
	margin: 10px 0 0;
	// img {
	// 	height: 100%;
	// 	// object-fit: cover;
	// }
}
