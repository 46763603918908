/**
 * Trimming - all
 */

main.trimming {
	h1 { margin: 15px 0 0; }
}
.trimming-left, .trimming-right { width: 100%; }

// Left content
.trimming-left {
	margin: 15px 0 0;
	> h2 {
		background: url(../img/common/annai_grade.jpg) no-repeat;
		color: #8b4513;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 1px;
		> span {
			font-size: 13px;
		}
	}
	> h3 {
		color: #8b4513;
		font-size: 16px;
		font-weight: bold;
		margin: 15px 0 0;
	}
	> p {
		font-size: 15px;
		margin: 10px 0 0;
	}
	> ol {
		color: #8b4513;
		font-size: 15px;
		line-height: 1.6;
		margin: 10px 0 0;
		> li {
			> span.text {
				color: #333;
				font-size: 14px;
			}
		}
	}
	> p.service-plan {
		border: 4px outset #FF9933;
		color: #8b4513;
		padding: 8px;
	}
}

// Right content
.trimming-right {
	margin: 30px 0 0;
	> h2 {
		color: #8b4513;
		font-weight: bold;
	}
	> p {
		font-size: 14px;
		margin: 10px 0 0;
	}
}

table.trimming {
	@extend .ms-gothic;
	font-size: 12px;
	margin: 15px 0 0;
	th, td {
		padding: 2px 4px;
		vertical-align: middle;
		&:first-child { width: 120px; }
	}
	thead {
		th { text-align: center; }
	}
	tbody {
		th, td { border-top: 1px solid #000; }
		th { text-align: left; padding-left: 0;}
		td { text-align: center; }
		td.colspan-3 {
			padding-left: 0;
			padding-bottom: 1em;
			text-align: left;
		}
	}
}

.trimming-option {
	border: 2px solid #0066FF;
	margin: 10px 0 0;
	padding: 10px;
	> h3 {
		font-size: 14px;
	}
	> ul {
		font-size: 12px;
		line-height: 1.6;
		margin: 5px -16px 0 0;
		> li {
			display: inline-block;
			margin: 0 16px 0 0;
			&:before { content: "・"; }
		}
	}
}