/**
 * Innai
 */

main.innai {
	margin: 20px 0 0;
}

figure.innai-mado {
	margin: 15px 0 0;
	> img { width: 100%; }
}

// Honin
section.honin {
	background-color: #fdf2e3;
	> h2 {
		margin: 15px 0 0;
		> img { width: 100%; }
	}
}

figure.innai {
	width: 50%;
	box-sizing: border-box;
	float: left;
	background: #fdf2e3;
	padding: 10px;
	text-align: center;
	> figcaption {
		display: block;
		font-size: 11px;
		margin: 2px 0 0;
	}
}

// Careroom
a.to-devices {
	width: 184px;
	background: url(../img/innai/botan02.jpg) no-repeat left center/contain;
	display: block;
	line-height: 35px;
	margin: 20px auto;
	padding: 0 0 0 40px;
	&:hover {
		background-image: url(../img/innai/botan01.jpg)
	}
}

.careroom-box {
	background: #fdf2e3;
	padding: 15px;
	> ul {
		font-size: 15px;
		margin: 5px 0 0;
		> li {
			position: relative;
			padding:0 0 0 1em;
			&:before {
				position: absolute;
				left: 0;
				right: 0;
				content: "●";
				display: inline;
			}
		}
	}
	> figure {
		margin: 15px 0 0;
		text-align: center;
		> figcaption {
			display: block;
			font-size: 11px;
			margin: 2px 0 0;
		}
	}
}

// Devices
main.devices {
	> h1 {
		color: #00984f;
		font-size: 17px;
		margin: 20px 0 0;
	}
}

.devices-figures {
	justify-content: center;
	> figure {
		margin: 15px 0 0;
		> img { width: 100%; }
	}
}
