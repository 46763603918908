/**
 * Info - all
 */

main.info {
	> h1 {
		margin: 15px 0 0;
	}
}
.info-left, .info-right { width: 100%; }

// Left content
.info-left {
	margin: 20px 0 0;
	> h2 {
		color: #8b4513;
		background: url(/img/common/annai_grade.jpg) no-repeat;
		font-size: 18px;
		letter-spacing: 1px;
	}
	> h2 + p {
		font-size: 15px;
		margin: 10px 0 0;
	}
	> h3 {
		color: #8b4513;
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0 0;
	}
	> h3 + p {
		font-size: 14px;
		margin: 5px 0 0;
	}
	> h3 + p + p { font-size: 12px; }
	> figure {
		margin: 15px 0 0;
	}
	> picture {
		margin: 30px 0 0;
		> img { width: 100%; }
	}
}

// Right content
.info-right {
	margin: 25px 0 0;
	> figure {}	
}

.info-job {
	> h2 {
		color: #8b4513;
		font-size: 18px;
		margin: none;
	}
	> h3 {
		border-bottom: 1px solid #00a249;
		color: #00a249;
		font-size: 16px;
		margin: 20px 0 10px;
	}
	> h4 {
		font-size: 13px;
		margin: 10px 0 5px;
	}
	> p {
		font-size: 13px;
		margin: 10px 0 0;
	}
	> ul {
		font-size: 13px;
		list-style: disc;
		padding: 0 0 0 1.5em;
		> li {
			&:nth-child(n+2) {
				margin: 2px 0 0;
			}
		}
	}
}

.info-job-contact {
	align-items: flex-start;
	background: #af2c6c;
	color: #fff;
	margin: 15px 0 0;
	padding: 15px;
	> .txt {
		width: calc(75% - 17px);
		font-size: 11px;
		> p + p { margin: 5px 0 0; }
		> ul {
			font-size: 13px;
			margin: 10px 0 0;
		}
	}
	a {
		color: #fff;
	}
	> picture {
		width: 25%;
		border: 1px solid #fff;
		margin: 0 0 0 15px;
	}
}

.info-notification {
	border: 2px solid #00a249;
	padding: 10px 10px 0;
	> p {
		font-size: 14px;
	}
	> p.tel {
		font-size: 17px;
		font-weight: bold;
		margin: 5px 0 0;
	}
	> picture {
		margin: 15px 0 10px -10px;
	}
}

.info-trimmer {
	align-items: flex-start;
	background: #af2c6c;
	color: #fff;
	margin: 15px 0 0;
	padding: 15px;
	> h2 {
		width: 100%;
		font-size: 16px;
	}
	> p {
		width: calc(75% - 17px);
		float: left;
		font-size: 11px;
		margin: 10px 0 0;
		> strong {
			display: block;
			font-size: 14px;
			font-weight: bold;
			margin: 10px 0 0;
			text-align: center;
		}
	}
	> picture {
		width: 25%;
		float: right;
		border: 1px solid #fff;
		margin: 10px 0 0 15px;
	}
}
