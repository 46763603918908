/**
 * Common CSS
 */

html, body {
	font-family: $hiragino;
	width: 100%;
}

body {
	background: url(../img/common/back_grade.jpg) repeat-x;
	line-height: 1.5;
}

h1.page-ttl {
	// text-indent: 40px;
	color: #3b653f;
	background: url(../img/common/icon_mado.jpg) no-repeat;
	font-size: 18px;
	line-height: 30px;
	font-weight: bold;
	padding-left: 40px;
}

strong, b { font-weight: bold; }

picture { display: block; }

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

.text-center { text-align: center; }
.text-green { color: #00984f; }
.text-blud { color: #3c7ec2; }
.text-brown { color: #b65f2c; }

.ms-gothic {font-family: $ms-gothic; }
.ib { display: inline-block; }

.clearfix {
	&:before, &:after {
		clear: both;
		content: '';
		display: table;
	}
}

.flex { display: flex; }
.wrap { flex-wrap: wrap; }

// Wrapper
#wrapper {
	width: 100%;
	max-width: 412px;
	margin: 0px auto;
	overflow: hidden;
	background: #FFF;
	border: 3px solid #523e36;
	box-sizing: border-box;
}

// Header
.header {
	background: #5b3b2e;
	align-items: center;
	justify-content: space-between;
	padding: 8px 0;
	position: relative;
}

address.head_address {
	color: #fff;
	font-size: 10px;
	line-height: 1.5;
	margin: 0 0 0 10px;
	> a { color: #fff; }
}

.mukyu-facebook {
	margin: 0 10px 0 0;
	> p {
		background: #fff;
		color: #5b3b2e;
		display: inline-block;
		font-size: 12px;
		line-height:1;
		padding: 5px;
	}
	> figure {
		display: inline-block;
		vertical-align: bottom;
	}
}

.head_title {
	margin: 8px 0 0;
	padding: 0 0 0 4px;
	width: 192px;
	> img { height: auto; }
}

button.nav-toggle {
	width: 40px;
	appearance: none;
	background: transparent;
	border: none;
	outline: none;
	padding: 0;
	position: absolute;
	right: 4px;
	bottom: 11px;
	> span {
		background: #fff;
		content: '';
		display: block;
		margin: 4px auto;
		width: 28px;
		height: 2px;
	}
	> small {
		color: #fff;
		display: block;
		font-size: 8px;
		line-height: 1;
		margin: 2px 0 0;
	}
}
button.nav-toggle.close {
	> span:first-child {
		transform: rotate(45deg) translate(2px, 2px);
	}
	> span:nth-child(2) { opacity: 0; }
	> span:nth-child(3) {
		transform: rotate(-45deg) translate(6px, -6px);
	}
}

// Global Navigation
#navibar {
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s ease-in-out;
	> ul {
		display: flex;
		flex-wrap: wrap;
		line-height: 1;
		> li {
			display: block;
			border-top: 1px solid #3c312e;
			margin: 0;
			width: 100%;
			> a {
				background: #5d504e;
				color: #e4e1e1;
				display: block;
				line-height: 20px;
				padding: 11px 0;
				text-align: center;
				text-decoration: none;
				text-shadow: 1px 1px 2px rgba(0,0,0,.5);
				sup {
					color: #fbf519;
					display: inline-block;
					font-size: 12px;
					margin: 0 0 0 2px;
					text-shadow: none;
					transform: translateY(-6px);
					// vertical-align: super;
				}
				br { display: none; }
			}
			&:last-child { border-bottom: 1px solid #3c312e; }
		}
	}
}

// Main
#main {
	// background: #FFF;
	padding: 0 20px 20px;
}

// Footer
#footer {
	background: #c74500;
	clear: both;
}

.copyright {
	color: #f2c3b5;
	font-family: serif;
	font-size: 11px;
	padding: 8px 0 6px;
	text-align: center;
}
